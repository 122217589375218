@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Libre Franklin", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

th {
  font-weight: inherit;
}

input {
  display: block;
  margin: auto;
  height: 3rem;
  width: 70%;
  margin-bottom: 1.5rem;
  padding-left: 3%;
  border: 1px #cecece solid;
  border-radius: inherit;
  outline-color: #112347;
  outline-width: 1px;
  background-color: inherit;
  background: inherit;
}
